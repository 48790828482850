<template>
  <el-card class="box-card">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item name="1">
        <template slot="title">操作提示</template>
        <div class="warn">
          同一个商品只能同时出现在一个有效时间内。
        </div>
      </el-collapse-item>
    </el-collapse>
    <el-form style="margin-top: 30px" ref="form" label-width="130px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="活动名称" prop="name">
        <el-tooltip placement="top">
          <div slot="content">活动名称将显示在限时折扣活动列表中，方便商家管理使用</div>
          <el-input v-model="form.name" size="small" placeholder="请输入活动名称" style="width: 25%"/>
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="备注" prop="remark">
        <el-tooltip placement="top">
          <div slot="content">备注是商家对限时折扣的补充说明文字，<br/>在商品详情页-优惠信息位置显示；非必填选项</div>
          <el-input
              type="textarea"
              :autosize="{ minRows: 2}"
              v-model="form.remark"
              placeholder="请输入活动备注"
              style="width: 25%"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动时间" prop="">
        <el-date-picker
            v-model="query_time"
            size="small"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
            value-format="yyyy-MM-dd"
            @change="getTimeSection"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="套餐商品" prop="">
        <el-table :data="s_list" size="small" style="width: 70%"
                  @selection-change="setSelectRows">
          <el-table-column align="center" show-overflow-tooltip type="selection"/>
          <el-table-column
              align="center"
              label="商品图片"
              prop="imgUrl"
          >
            <template #default="{row}">
              <el-image v-if="row.imgUrl" :src="row.imgUrl" class="commodity_pic"/>
              <span v-else>暂无图片</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="商品名称"
              prop="name"
          />
          <el-table-column
              align="center"
              label="商品价格"
              prop="original_price"
          />
          <el-table-column
              align="center"
              label="库存"
              prop="stock"
          />
          <el-table-column
              align="center"
              label="折扣价"
              prop="price"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.price" v-model="s_list[scope.$index].price"
                        placeholder="请输入内容" :value="scope.row.price"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].price" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="100">
            <template #default="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button icon="el-icon-plus" type="primary" @click="showPop = true" size="small" style="margin-top: 10px">
          选择商品
        </el-button>
        <el-button v-if="s_list.length > 0" icon="el-icon-delete" size="small" type="danger" style="margin-top: 10px"
                   @click="handleDelete">批量删除
        </el-button>
        <el-button v-else icon="el-icon-delete" type="danger" size="small" style="margin-top: 10px" disabled>批量删除
        </el-button>
        <PopView :visible.sync="showPop" :chooseGoods="chooseGoods" title="选择商品" @confirm="showPopSubmit"></PopView>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" size="small">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>

import PopView from "@/views/components/popView";
import {apiGetDiscount} from "@/request/api";

export default {
  components: {PopView},
  props: {
    chooseGoods: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPop: false,
      activeName: ["1"],
      query_time: [],
      selectRows: '',
      list: [],
      s_list: [],
      form: {
        id: '',
        name: '',
        remark: '',
        begin_time: '',
        end_time: '',
        goods: [],
      },
      rules: {
        name: [
          {required: true, trigger: 'blur', message: '活动名称不能为空'},
        ],
        remark: [
          {required: true, trigger: 'blur', message: '活动备注不能为空'},
        ],
        /*begin_time: [
          {required: true, trigger: 'blur', message: '活动时间不能为空'},
        ],*/
      },
    }
  },
  created() {
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    //获取时间段
    getTimeSection(event) {
      if (event != null) {
        this.form.begin_time = event[0]
        this.form.end_time = event[1]
      } else {
        this.form.begin_time = ''
        this.form.end_time = ''
      }
    },
    showPopSubmit(e) {
      this.showPop = true;
      console.log(e)
      if (e) {
        e.forEach(item => {
          this.s_list.push({
            sku_id: item.skuid,
            imgUrl: item.gallery,//商品图片
            name: item.name,//商品名
            original_price: item.price,//价格
            stock: item.stock,//库存
            price: item.price,//折扣价
          })
        })
        const smap = new Map()
        // 对id属性进行去重
        this.s_list = this.s_list.filter(key => !smap.has(key.sku_id) && smap.set(key.sku_id, 1))
        this.listLoading = false
      }
    },
    //删除选中商品
    handleDelete(row) {
      if (row.sku_id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.s_list.map((val, i) => {
            if (val.sku_id == row.sku_id) {
              this.s_list.splice(i, 1)
            }
          })
        })
      } else {
        this.$confirm('你确定要删除所有选中项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          for (var j = 0; j < this.selectRows.length; j++) {
            this.s_list.map((val, i) => {
              if (val.sku_id == this.selectRows[j].sku_id) {
                this.s_list.splice(i, 1)
              }
            })
          }
        })
      }
    },
    //清空表单
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.query_time = []
      this.s_list = []
    },
    async submit() {
      this.list = []
      if (this.s_list.length > 0) {
        this.s_list.forEach(item => {
          this.list.push({
            sku_id: item.sku_id,
            price: item.price,//低价
          })
        })
      } else {
        this.$message.error('参与活动商品不能为空')
        return
      }
      const map = new Map()
      this.form.goods = this.list.filter(key => !map.has(key.sku_id) && map.set(key.sku_id, 1))
      apiGetDiscount(this.form).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.close()
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}

</script>
<style>
.warn {
  background-color: #fdf6ec;
  color: #e6a23c;
  padding: 8px 16px;
  font-size: 13px;
}
</style>
